<template>
  <div class="convention-hall page">
    <van-nav-bar class="nav-bar" :title="$t('concubine.concubine')">
      <template #right>
        <van-image
          width="18"
          height="18"
          src="/img/clm.png"
          style="margin-right: 10px;"
								@click="isShow=false"
        />
        <van-image
          width="22"
          height="22"
          src="/img/row.png"
					@click="isShow=true"
        />
      </template>
    </van-nav-bar>
    <div style="height: 160px;">
      <swiper class="banner_swiper" :options="bannerSwiperOption">
        <swiper-slide v-for="(v, key) in banners" :key="key">
          <van-image
            class="banner_img"
            :src="`https://${v.url}`"
            width="100%"
            height="160px"
          >
            <template v-slot:loading>
              <van-loading type="circular" />
            </template>
          </van-image>
        </swiper-slide>
      </swiper>
    </div>

    <div style="padding-bottom: 40px;background-color: #fff;">
      <van-tabs
        v-model="active"
        title-active-color="#C571CF"
        line-height="6px"
        line-width="31px"
      >
       <!-- <van-tab
          :title="item.name"
          v-for="(item, index) in tabList"
          :key="index"
        > -->
        
          <van-list
            v-model="loading"
          
            @load="onLoad"
          >
					<!-- :finished="finished"
					finished-text="没有更多了" -->
            <div class="pblBox" v-show="!isShow">
              <div style="width: calc(50% - 5px);" v-for="item in data" :key="item.id" @click="toDetail(item.id)">
                <div class="releaseItem">
                  <div class="imgBox">
                    <div class="van-image" style="width: 100%; height: 100%;">
                      <img
                        :src="item.img_url"
                        class="van-image__img"
                      />
                    </div>
                    <span><i>{{ $t('concubine.high_end') }}</i></span>
                    <div class="infoBox">
                      <i
                        class="van-icon van-icon-location"
                        style="font-size: 14px;"
                        ><!----></i
                      ><!-- <span
                        style="font-size: 12px; margin: 0px 0px 0px 2px;"
                      ></span> -->
                      <div class="wantyuebtn">{{ $t('concubine.appointment') }}</div>
                    </div>
                  </div>
                  <!-- <div class="tagBox" style="color: rgb(102, 102, 102);">
                    {{ $t('concubine.beautiful_girl') }}
                  </div> -->
                  <div class="di">
                    <div>
                      <span
                        style="
                          display: inline-block;
                          margin-right: 2px;
                          width: 12px;
                          height: 12px;
                          border-radius: 50%;
                          background: rgb(239, 186, 143);
                        "
                      ></span
                      ><span>{{ item.xuanfei_name }}</span>
                    </div>
                    <div>
                      <i
                        class="van-icon van-icon-like"
                        style="color: rgb(220, 99, 161);"
                        ><!----></i
                      ><span>{{ item.dianzan }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
						<div class="horizontalItem" v-show="isShow" v-for="item in data" :key="item.id"  @click="toDetail(item.id)">
          <div  class="imgBox">
            <div
              
              class="van-image"
              style="
                width: 120px;
                height: 120px;
                overflow: hidden;
                border-radius: 8px;
              "
            >
              <img
                :src="item.img_url"
                class="van-image__img"
                style="object-fit: cover;"
              />
            </div>
            <span ><i >{{ $t('concubine.high_end') }}</i></span>
          </div>
          <div  style="width: calc(100% - 140px);">
            <div  class="top">
              <div >
                <div  class="van-image" style="width: 13px;">
                  <img
                    src="/img/vip.png"
                    style="width: 13px; height: 13px;"
                    class="van-image__img"
                  />
                </div>
                <span >{{$t("index.authentication")}}</span>
              </div>
              <div >
                <div  class="van-image" style="width: 13px;">
                  <img
                    src="/img/vip.png"
                    class="van-image__img"
                    style="width: 13px; height: 13px;"
                  />
                </div>
                <span >{{$t("index.video_authentication")}}</span>
              </div>
              <div
                
                style="
                  width: 70px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
               {{ item.xuanfei_name }}
              </div>
            </div>
            <div  class="tags">
              <van-tag
        v-for="(tag, index) in item.flagArray"
        :key="index"
        :type="tagTypes[index]"
      >
        {{ tag }}
      </van-tag>
              
            </div>
            <!----><!---->
            <div  style="display: flex; align-items: center;">
              <span
                
                style="font-size: 14px; color: rgb(102, 102, 102);"
                >{{$t("index.toll")}}：</span
              >
              <van-rate v-model="item.number"   color="#ffd21e" size="20px" />
            </div>
            <div
              
              style="
                color: rgb(153, 153, 153);
                font-size: 12px;
                margin: 5px 0px;
              "
            >
          
            {{$t("index.height")}}: {{item.hight}}cm {{$t("index.bust")}}：{{ item.bust }} {{$t("index.address")}}： {{$t("index.National")}}：{{$t("index.yes")}}
            </div>
            <div  class="yueta">{{$t("index.about")}}</div>
          </div>
        </div>
          </van-list>
        <!-- </van-tab> -->
      </van-tabs>
    </div>
		
  </div>
</template>

<script>
export default {
  data() {
    return {
			addlist: [],
			isShow:false,
      banners: [{}],
      basicData: [],
      active: 0,
      tabList: [],
      data: [],
      page: 0,
			ids: 1,
			tagTypes: ['primary', 'success', 'danger', 'warning'],
      bannerSwiperOption: {
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        speed: 800,
        autoplay: true,
        page: 0,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        },
      },
    };
  },
  methods: {
    getxuanfeilist() {
		const selid = this.getCookie('selid'); 
      this.$http({
        method: "get",
        url: "xuanfeilist",
        data: { id: selid, page: this.page },
      }).then((res) => {
				this.data = res.data;
        const lanng = localStorage.getItem("lang");
				this.data.forEach(item => {
					const parts = item.flag.split('|')
			
          lanng == 'zh'? item.flag = parts[0]:lanng == 'en'? item.flag = parts[1]: lanng == 'ja'?item.flag = parts[2]:item.flag = parts[3]
          item.flagArray = item.flag.split('，');
         
				})
				console.log(this.data,900)
      });
    },
		onLoad() { },
		toDetail(id) {
      this.$router.push({ path: "/detail", query: { id: id } });
    },
	getCookie(name) {
	  name = name + '=';
	  const decodedCookie = decodeURIComponent(document.cookie);
	  const cookies = decodedCookie.split(';');
	  for (let i = 0; i < cookies.length; i++) {
	    let cookie = cookies[i];
	    while (cookie.charAt(0) === ' ') {
	      cookie = cookie.substring(1);
	    }
	    if (cookie.indexOf(name) === 0) {
	      return cookie.substring(name.length, cookie.length);
	    }
	  }
	  return '';
	},
    getAddress() {
      this.$http({
        method: "get",
        url: "address_list",
      }).then((res) => {
        this.tabList = res.data[0];
        this.tabList.forEach((item) => {
          item.name = item.name.split(",")[0];
        });
      });
    },
    getBasicConfig() {
      this.$http({
        method: "get",
        url: "sys_config",
      }).then((res) => {
        this.basicData = res.data;

        this.getBanner(this.basicData); //获取banner
      });
    },
    getBanner(data) {
      this.banners = data.banners;
    },
  },
  created() {
    this.getAddress();
    this.getBasicConfig();
    this.getxuanfeilist();
  },
};
</script>

<style lang="less" scoped>
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.nav-bar {
  background: linear-gradient(30deg, #7d76ef, #d63f8c);
  height: 100px;
}
.van-nav-bar {
  line-height: 50px;
}
::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}
.van-sidebar {
  width: 180px;
}
// /deep/ .van-col{
// padding: 30px 0px;
// }
.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #f2f2f5;
}
::v-deep .van-tab {
  font-size: 30px;
  line-height: 100px;
  font-weight: bold;
}
::v-deep .van-tabs__line {
  background-color: #c571cf;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}

::v-deep .van-row--flex {
  height: 80px;
  line-height: 80px;
}

/deep/ .van-cell {
  padding: 30px 22px;
  font-size: 30px;
  line-height: 30px;
}
.pblBox {
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  flex-wrap: wrap;
}
.releaseItem {
  width: 100%;
  margin-bottom: 30px;
}
.releaseItem .imgBox {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  height: 67vw;
}
.tagBox {
  font-size: 3vw;
  margin: 10px 0;
}
.di {
  display: flex;
  justify-content: space-between;
  font-size: 3vw;
  color: #666;
}
.releaseItem .imgBox span {
  position: absolute;
  z-index: 9;
  background-color: #ebcaaf;
  color: #8d684b;
  transform: rotate(45deg);
  width: 20vw;
  height: 20vw;
  top: -11vw;
  right: -11vw;
}
.releaseItem .imgBox span i {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
  font-size: 4vw;
}
.releaseItem .imgBox .infoBox {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  color: #fff;
  width: 100%;
  height: 8vw;
  padding: 0 20px;
}
.releaseItem .imgBox .infoBox .wantyuebtn {
  border: 2px solid #fff;
  padding: 10px 16px;
  border-radius: 16px;
  opacity: 0.9;
  font-size: 3vw;
  margin-left: auto;
}

.horizontalItem .top div:first-child,
.horizontalItem .top div :nth-child(2) {
  background: linear-gradient(180deg, #e7caaf, #fff7ed);
  color: #a4826b;
  font-weight: 600;
  font-size: 3vw;
  padding: 3px;
  margin-right: 10px;
}
.horizontalItem .top div:first-child,
.horizontalItem .top div:nth-child(2) {
  background: linear-gradient(180deg, #e7caaf, #fff7ed);
  color: #a4826b;
  font-weight: 600;
  font-size: 3vw;
  padding: 3px;
  margin-right: 10px;
}
.horizontalItem .tags {
  display: flex;
  margin: 10px 0 10px;
  flex-wrap: wrap;
}
.horizontalItem .tags .van-tag {
  padding: 15px;
  font-size: 3vw;
  margin: 0 10px 10px 0;
  border-radius: 16px;
}
.horizontalItem .yueta {
  background: linear-gradient(90deg, #df35ad, #4f1db5);
  color: #fff;
  border-radius: 16px;
  padding: 20px 0;
  text-align: center;
  font-size: 3vw;
}
.horizontalItem {
  display: flex;
  border-bottom: 2px solid #eee;
  padding: 40px 0 4vw;
}
.horizontalItem .imgBox {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  margin-right: 40px;
}
.horizontalItem .imgBox span {
  position: absolute;
  z-index: 9;
  background-color: #ebcaaf;
  color: #8d684b;
  transform: rotate(45deg);
  width: 20vw;
  height: 20vw;
  top: -11vw;
  right: -11vw;
}
.horizontalItem .imgBox i {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 700;
  font-size: 4vw;
}
.top {
  display: flex;
}
</style>
