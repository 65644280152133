<template>
  <div class="container page">
    <van-nav-bar :title="$t('setting.language')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="items">
      <div
        class="item van-hairline--bottom"
        @click="changeLang('zh', $event)"
      >
        <div class="flex_center">
          
          <div class="info">Thai</div>
        </div>
        <div v-if="lang == 'zh'">✓</div>
      </div>
      <div
        class="item van-hairline--bottom"
        @click="changeLang('en', $event)"
      >
        <div class="flex_center">
        
          <div class="info">English</div>
        </div>
        <div v-if="lang == 'en'">✓</div>
      </div>
       <div
        class="item van-hairline--bottom"
        @click="changeLang('ja', $event)"
      >
        <div class="flex_center">
        
          <div class="info">日本語では</div>
        </div>
        <div v-if="lang == 'ja'">✓</div>
      </div>

      <div
        class="item van-hairline--bottom"
        @click="changeLang('kr', $event)"
      >
        <div class="flex_center">
       
          <div class="info">한국어</div>
        </div>
        <div v-if="lang == 'kr'">✓</div>
      </div> 
    </div>
  </div>
</template>
  
  <script>


import { Toast } from "vant";
export default {
  name: "Language",
  data() {
    return {
      lang: this.$i18n.locale || "zh",
      source: ''
    };
  },
    created() {
    this.replaceLang()
  },
  mounted() {
    this.source=this.$route.params.type
  },
  methods: {
    back(){
      return window.history.back();
    },
    replaceLang() {
      this.$http({
        method: "get",
        url: "replaceLang",
        data: {
          lang: this.lang,
        },
      }).then((res) => {
      console.log(res,11)
      });
    },
    changeLang(lang) {
      
      Toast.loading({
        // mask: true,
        duration: 200,
      });
      this.lang = lang;
      this.$i18n.locale = lang;
      this.replaceLang()
      localStorage.setItem("lang", lang);
   
      if (this.source=='setting'){
        this.$router.push({path:'/'});
      }else{
        this.$router.go(-1);
      }
    },
    }

};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.container .items {
  background-color: #fff;
  font-size: 30px;
  color: #000;
  padding:  25px;
}
.container .items .item {
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 10px;
}
.container .items .van-hairline--bottom::after {
  border-bottom-width: 3px;
  padding: 10px 0;
}
.container .sign-out {
  margin: 500px 20px 0;
  height: 100px;
  line-height: 100px;
  border-radius: 50px;
  color: #fff;
  font-size: 40px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(270deg, #e6c3a1, #7e5678);
}
.container .item .desc {
  font-size: 30px;
  font-weight: 700;
  color: #979799;
}
.container .item .right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex_center {
  display: flex;
  align-items: center;
}
.flex_center img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}
</style>
